.loadingModal {
    background-color: rgb(255 255 255 / 50%);
}
.loadingModal .modal-content {
    background-color: transparent;
    box-shadow: none;
}
.loadingModal .modal-body {
    text-align: center;
}
.grecaptcha-badge {
    box-shadow: none !important;
}
.qr-scanner {
    width: 100%;
}
.react-date-picker__wrapper {
    border: 0;
}
.react-date-picker__wrapper .react-date-picker__button {
    padding: 0;
}
.react-date-picker__wrapper .react-date-picker__inputGroup__input {
    background-color: transparent;
    outline: transparent;
}